import React from "react"
import styled from 'styled-components'
import { color, media } from '../Styles'
import { Image } from '../Images'
import Button from '../Button'
import Enomac from "../../images/home/Homepage-EnomacICON.svg"
import { useTranslation } from "react-i18next"
import Slide from 'react-reveal/Fade';
import Accordion from 'react-bootstrap/Accordion';


const TextSide = ({ image, alt, info, title, subtitle, content, btn, small, ceo, reverse, data }) => {
    const { t } = useTranslation()

    return (
        <>
            {reverse ?
                <TextSideStyled small={small} ceo={ceo}>
                    <div className='wrapper'>
                        <Slide left>
                            {image &&
                                <div className='image-container'>
                                    <Image src={image} alt={alt} />
                                    {info &&
                                        <span className='info'>
                                            <Enomac />
                                        </span>
                                    }
                                </div>
                            }
                        </Slide>
                        <Slide right>
                            <section className='content'>
                                {(title || subtitle) &&
                                    <div className='title'>
                                        <h2>{title}</h2>
                                        <span className='subtitle'>{subtitle}</span>
                                    </div>
                                }

                                {content &&
                                    <span className='description' dangerouslySetInnerHTML={{ __html: content }}></span>
                                }

                                {ceo &&
                                    <div className='ceo-container'>
                                        <span className='name'>Sérgio Queirós</span>
                                        <span className='ceo'>CEO</span>
                                    </div>
                                }
                                {btn &&
                                    <Button data={t("home", { returnObjects: true })} />
                                }
                            </section>
                        </Slide>
                    </div>
                </TextSideStyled>
                :
                <EnomacStyled>
                    <div className='wrapper'>
                        <Slide left>
                            <div className='image-container'>
                                <Image src={image} alt={alt} />
                            </div>
                        </Slide>
                        <Slide right>
                            <section className='content'>
                                <Accordion className="accordion-container" defaultActiveKey="0">
                                    <Accordion.Item eventKey="0" className="container-item" >
                                        <Accordion.Header className="container-header">
                                            {data.enomac.ceo.accordion[0].title}
                                        </Accordion.Header>
                                        <Accordion.Body>
                                            <p className="optiontxt">{data.enomac.ceo.accordion[0].text}</p>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="1" className="container-item" >
                                        <Accordion.Header className="container-header">
                                            {data.enomac.ceo.accordion[1].title}
                                        </Accordion.Header>
                                        <Accordion.Body>
                                            <p className='optiontxt' dangerouslySetInnerHTML={{ __html: data.enomac.ceo.accordion[1].text }} />

                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="2" className="container-item" >
                                        <Accordion.Header className="container-header">
                                            {data.enomac.ceo.accordion[2].title}
                                        </Accordion.Header>
                                        <Accordion.Body>
                                            <p className="optiontxt">{data.enomac.ceo.accordion[2].text}</p>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>
                            </section>
                        </Slide>
                    </div>
                </EnomacStyled>
            }
        </>
    )
}

export default TextSide

const TextSideStyled = styled.div`
    position:relative;

    .wrapper{
        max-width:1920px;
        margin:0 auto;
        padding:clamp(30px,15vw,80px) 10%;
        color:${color.black};
        display:grid;
        grid-template-columns:0.5fr 1fr;
        gap:5%;

        ${media.xl`
            grid-template-columns:repeat(2,1fr);
        `}

        ${media.l`
            grid-template-columns:1fr;
            gap:3rem;
        `}

        .image-container{
            position:relative;
            
            .image{
                height:100%;
                max-height: ${props => props.small ? '600px' : '750px'};
                width: ${props => props.info ? 'calc(100% - 75px)' : '100%'};
                margin-left: auto;

                ${media.m`
                    width:${props => props.info ? 'calc(100% - 50px)' : '100%'};
                `}
            }

            .info{
                position:absolute;
                top:15%;
                left:-70px;
                width:150px;
                height:150px;
                background:${color.black};
                color:${color.yellow};
                z-index:1;
                border-radius: 50%;
                display: flex;
                align-items: center;
                justify-content: center;
                text-align: center;
                font-weight:800;
                text-transform:uppercase;

                ${media.m`
                    width:100px;
                    height:100px;
                    font-size:0.8rem;
                `}

                ${media.m`
                    left: -35px;
                `}
            }
        }

        .content{
            display:flex;
            flex-direction:column;
            justify-content: flex-start;
            gap:3rem;

            .title{
                h2{
                    font-weight: 700;
                    font-size: ${props => props.small ? '2rem' : '2rem'};
                    text-transform: ${props => !props.small && 'uppercase'};
                    font-style: ${props => props.ceo && 'italic'};
                    margin-bottom: 15px;
                }

                .subtitle{
                    font-weight:400;
                    font-size:1.8rem;
                    margin-bottom:1rem;

                    ${media.m`
                        font-size:1.3rem;
                    `}
                }
            }

            .description{
                line-height:1.8rem;

                ul{
                    margin:0;
                    padding-left:20px;

                    li{
                        font-weight:500;
                        font-size:1.5rem;
                        line-height:2rem;
                    }
                }
            } 
            
            .ceo-container{
                margin-left: auto;
                display: flex;
                flex-direction: column;
                gap: 0.5rem;
                text-align: right;

                .name{
                    font-weight:600;
                    font-size:2rem;
                }

                .ceo{
                    font-size:1.2rem;
                }
            }
        }
    }
`

const EnomacStyled = styled.div`
    position:relative;

    .wrapper{
        max-width:1920px;
        margin:0 auto;
        padding:clamp(30px,15vw,80px) 10%;
        color:${color.black};
        display:grid;
        grid-template-columns:0.5fr 1fr;
        gap:5%;

        ${media.xl`
            grid-template-columns:repeat(2,1fr);
        `}

        ${media.l`
            grid-template-columns:1fr;
            gap:3rem;
        `}


        .content{
            display:flex;
            flex-direction:column;
            justify-content: flex-start;
            gap:3rem;

            .accordion-container {
                .container-item {
                    .container-header {
                        background-color: #fff;

                        .accordion-button:not(.collapsed) {
                            background-color: ${color.yellow};
                            font-weight: bold;
                            color: #000;
                            text-decoration: none;
                        }
                        h3 {
                            color: #000;
                            
                        }
                    }
                    .optiontxt {
                        padding: 2rem 4rem;

                        ${media.l`
                            padding: 2rem;
                        `}
                    }
                }
            }
        }
    }
`